import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import React from 'react';

import { Row, Col, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Hero } from '../components/global/Hero';
import { SOCIALLINKS } from '../constants';

function Contact() {
   const [t] = useTranslation('contact');

   return (
      <>
         <Helmet>
            <title>{t('meta.title')}</title>
            <meta name="description" content={t('meta.description')} />
            <meta name="keywords" content={t('meta.keywords')} />
         </Helmet>

         <Container>
            <div className="py-4">
               <Hero intent="vture-dark-cyan" namespace="contact" noAnchor />
            </div>
         </Container>
         <div className="dark-background">
            <Container className="py-4">
               <h1 className="text-center text-vture-yellow py-4">{t('reachUsTitle')}</h1>
               <Row className="pb-2">
                  <Col sm={12} md={4} className="pb-4">
                     <h3>{t('phoneTitle')}</h3>
                     <p>{t('phoneNumber')}</p>
                     <h3>{t('mailTitle')}</h3>
                     <p>
                        <a className="custom-link-yellow" href="mailto:kontakt@vture.de">
                           {t('mailAddress')}
                        </a>
                     </p>
                  </Col>
                  <Col sm={12} md={4} className="pb-5">
                     <h3>{t('socialTitle')}</h3>
                     <p>{t('socialText')}</p>
                     <a href={SOCIALLINKS.LINKEDIN.VTURE}>
                        <FontAwesomeIcon icon={['fab', 'linkedin']} size="3x" />
                     </a>{' '}
                     <a href={SOCIALLINKS.TWITTER.VTURE}>
                        <FontAwesomeIcon icon={['fab', 'twitter-square']} size="3x" />
                     </a>
                  </Col>
                  <Col sm={12} md={4} className="pb-4">
                     <h3>{t('addressTitle')}</h3>
                     <p>
                        {t('addressCompany')}
                        <br />
                        {t('addressStreet')}
                        <br />
                        {t('addressTown')}
                        <br />
                        {t('addressCountry')}
                     </p>
                  </Col>
               </Row>
               <p>{t('reachUsText')}</p>
               <h1 className="text-center text-vture-light-cyan py-4">{t('moreTitle')}</h1>
               <p>
                  {t('moreText')}{' '}
                  <a className="custom-link-light-cyan" href="https://www.vture.de">
                     www.vture.de
                  </a>
               </p>
            </Container>
         </div>
      </>
   );
}

export default Contact;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
   }
`;
