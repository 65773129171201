import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

export function AnchorLink({ offset = 90, onClick, ...rest }) {
   useEffect(() => smoothscroll.polyfill(), []);

   function smoothScroll(e) {
      e.preventDefault();
      let offsetValue = () => 0;
      if (typeof offset !== 'undefined') {
         if (!!(offset && offset.constructor && offset.apply)) {
            offsetValue = offset;
         } else {
            offsetValue = () => parseInt(offset);
         }
      }
      const id = e.currentTarget.getAttribute('href').slice(1);
      const $anchor = document.getElementById(id);
      const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
         top: offsetTop - offsetValue(),
         behavior: 'smooth',
      });
      if (onClick) {
         onClick(e);
      }
   }

   return <a {...rest} onClick={smoothScroll} />;
}
