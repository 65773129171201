import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { AnchorLink } from '../helper/AnchorLink';

import { container, insideContainer, contentContainer, title, titleLarger, subtitle } from './hero.module.scss';
import classNames from 'classnames';

export function Hero({ intent, namespace = '', switchHeadings = false, noAnchor = false }) {
   const [t] = useTranslation(namespace);
   const buttonIntent = `btn-outline-${intent}`;

   return (
      <section id="top">
         <div className={classNames(container, 'text-center')}>
            <div className={insideContainer}>
               <div className={contentContainer}>
                  {switchHeadings ? (
                     <>
                        <div className={title}>{t('hero.title1')}</div>
                        <div className={titleLarger}>{t('hero.title2')}</div>
                     </>
                  ) : (
                     <>
                        <div className={titleLarger}>{t('hero.title1')}</div>
                        <div className={title}>{t('hero.title2')}</div>
                     </>
                  )}

                  <div className={subtitle}>{t('hero.subtitle')}</div>
                  {!noAnchor && (
                     <div className="text-center mt-5">
                        <AnchorLink offset="50" href="#0" className={classNames('btn btn-lg', buttonIntent)}>
                           {t('hero.action')}
                        </AnchorLink>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </section>
   );
}
